import React, { useState, useContext, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'
import { motion, AnimatePresence } from 'framer-motion'
import { useMount } from 'react-use'

import { Layout } from '../components'
import { media } from '../styles/utils'
import { container, padding, button } from '../styles/global'
import { parseACF, getGlobalData } from '../utils'
import { BGVideoContext } from '../context/BGVideoContext'

const Submission = props => {
    const { previewData, pageData, pageContext } = props
    const data = previewData || pageContext.data
    const prevLink = pageContext?.prevLink
    const nextLink = pageContext?.nextLink
    const [rationale, setRationale] = useState(false)
    // const videoContext = useContext(BGVideoContext)

    // useMount(() => {
    //     if (videoContext.activeVideo !== data.background_video) {
    //         videoContext.setActiveVideo(data.background_video)
    //     }
    // })

    // useEffect(() => {
    //     if (videoContext.activeVideo !== data.background_video) {
    //         videoContext.setActiveVideo(data.background_video)
    //     }
    // }, [videoContext])

    const duration = 0.45
    const variants = {
        show: {
            opacity: 1,
            transition: {
                duration: duration,
            },
        },
        hide: {
            opacity: 0,
            transition: {
                duration: duration,
            },
        },
    }

    const renderImage = image => {
        console.log(image)
        if (image && image.type === 'application') return

        // resolve image width
        let width = 'full'
        if (data.artwork.image_2) {
            width = 'half'
        }
        if (data.artwork.image_3) {
            width = 'third'
        }

        return (
            <ImageItem itemWidth={width}>
                <ImageWrapper>
                    <Image key={image.sizes.medium2} src={image.sizes.medium2}>
                        {(src, loading) => {
                            return (
                                <LoadedImage
                                    src={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                </ImageWrapper>
            </ImageItem>
        )
    }

    const renderImages = () => {
        return (
            <ImageGrid
                animate={!rationale ? 'show' : 'hide'}
                variants={variants}
            >
                {data.artwork.image && renderImage(data.artwork.image)}
                {data.artwork.image_2 && renderImage(data.artwork.image_2)}
                {data.artwork.image_3 && renderImage(data.artwork.image_3)}
            </ImageGrid>
        )
    }

    const renderIframe = () => {
        if (!data.artwork.iframe_embed_code) return

        return (
            <IframeWrapper
                animate={!rationale ? 'show' : 'hide'}
                variants={variants}
                dangerouslySetInnerHTML={{
                    __html: data.artwork.iframe_embed_code,
                }}
            />
        )
    }

    const renderArtwork = () => {
        return (
            <>
                {!data.artwork.type && renderImages()}
                {data.artwork.type === 'image' && renderImages()}
                {data.artwork.type === 'iframe' && renderIframe()}
            </>
        )
    }

    const renderRationale = () => {
        if (!data.rationale) return

        return (
            <TextWrapper
                animate={rationale ? 'show' : 'hide'}
                variants={variants}
            >
                <Description
                    dangerouslySetInnerHTML={{
                        __html: data.rationale,
                    }}
                />
            </TextWrapper>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                <Grid>
                    <Top>
                        <Left>
                            <Heading>{data.title}</Heading>
                            {data.rationale && (
                                <ContentToggle
                                    onClick={() => setRationale(!rationale)}
                                >
                                    {rationale ? 'Artwork' : 'Info'}
                                </ContentToggle>
                            )}
                        </Left>
                        <Nav>
                            {data.rationale && (
                                <ContentToggle
                                    onClick={() => setRationale(!rationale)}
                                >
                                    {rationale ? 'Artwork' : 'Info'}
                                </ContentToggle>
                            )}
                            {prevLink && (
                                <Prev as={Link} to={prevLink}>
                                    Previous
                                </Prev>
                            )}
                            {prevLink && nextLink && <span>&nbsp;/&nbsp;</span>}
                            {nextLink && (
                                <Next as={Link} to={nextLink}>
                                    Next
                                </Next>
                            )}
                        </Nav>
                    </Top>

                    <Content mobileScroll={data.artwork.image_2 || data.artwork.image_3 ? true : false}>
                        <ContentWrapper>
                            {renderArtwork()}
                            {renderRationale()}
                        </ContentWrapper>
                    </Content>

                    <Bottom>
                        <Back as={Link} to={`/${data.word[0].slug}`}>
                            <span className="capitalize">
                                {data.word[0].slug}
                            </span>
                        </Back>

                        <Link to={`/`}>Digæst</Link>
                    </Bottom>
                </Grid>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const Button = styled.div``
const Item = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    ${tw`w-full h-full`};

    ${media.mob`
        min-height: 100%;
        overflow-y: auto;
    `}
`

const Grid = styled.div`
    ${tw`flex flex-col justify-between h-full`};
    max-height: 100%;
    overflow: hidden;
`

// Top

const Left = styled.div``
const ContentToggle = styled.div`
    cursor: pointer;
    text-decoration: underline;
`

const Nav = styled.div``
const Next = styled.div``
const Prev = styled.div``

const Top = styled.div`
    ${tw`flex justify-between w-full`};

    ${media.mob`
        ${tw`fixed inset-0 flex-wrap`};
        bottom: initial;
        ${padding};
        z-index: 9;
    `}

    ${Left} {
        ${tw`flex flex-col`};

        ${media.mob`
            ${tw`w-full`};

            ${ContentToggle} {
                display: none;
            }
        `}
    }

    ${Nav} {
        ${tw`flex`};

        ${media.mob`
            ${tw`justify-end items-center w-full mt-2`};
        `}

        ${ContentToggle} {
            display: none;
        }

        ${media.mob`
            ${ContentToggle} {
                ${tw`flex mr-auto`};
            }
        `}
    }
`

// Content

const TextWrapper = styled(motion.div)``
const ImageGrid = styled(motion.div)``
const ImageItem = styled.div`
    ${props => {
        if (props.itemWidth === 'full')
            return css`
                width: 100%;
            `
    }}
    ${props => {
        if (props.itemWidth === 'half')
            return css`
                width: calc(33.33% - 20px);
                margin: 0 20px;

                ${media.mob`
                    ${tw`w-full`};
                    margin: 0;
                `}
            `
    }}
    ${props => {
        if (props.itemWidth === 'third')
            return css`
                width: calc(33.33% - 20px);

                ${media.mob`
                    ${tw`w-full`};
                `}

                &:nth-child(2) {
                    margin: 0 40px;

                    ${media.mob`
                        margin: 0;
                    `}
                }
            `
    }}
`
const ImageWrapper = styled.div``

const ContentWrapper = styled.div``
const Content = styled.div`
    flex: 1;
    ${tw`w-full h-full py-4`};
    overflow: hidden;

    ${media.mob`
        ${tw`py-32`};

        ${props => {
            if (props.mobileScroll)
                return css`
                    ${tw`pb-0`};
                    overflow-y: scroll;
                    
                    &, * {
                        height: auto;
                    }
                `
        }}
    `}

    ${ContentWrapper} {
        ${tw`relative w-full h-full`};
    }

    ${ImageGrid} {
        ${tw`flex justify-center w-full h-full`};

        ${props => {
            if (props.mobileScroll)
                return css`
                    ${media.mob`
                        ${tw`flex-col justify-start`};
                    `}
                `
        }}

        ${ImageItem} {
            ${tw`h-full`};

            ${props => {
                if (props.mobileScroll)
                    return css`
                        ${media.mob`
                            margin-bottom: 40px;
                        `}
                    `
            }}
        }
    }

    ${ImageWrapper} {
        ${tw`relative w-full h-full`};
    }

    ${Image}, ${LoadedImage} {
        ${tw`absolute inset-0 w-full h-full`};
        object-fit: contain;
        object-position: center;

        ${props => {
            if (props.mobileScroll)
                return css`
                    ${media.mob`
                        ${tw`static`};
                    `}
                `
        }}
    }

    ${TextWrapper} {
        ${tw`absolute inset-0 w-full h-full flex items-center`};

        ${media.mob`
            ${tw`items-start`};
            bottom: initial;
            overflow-y: scroll;
        `}
    }
`

const IframeWrapper = styled(motion.div)`
    ${tw`relative flex flex-col justify-center items-center w-full h-full`};
    z-index: 9;
`

// Bottom

const Back = styled.div``

const Bottom = styled.div`
    ${tw`flex justify-between`};

    ${media.mob`
        ${tw`fixed inset-0`};
        top: initial;
        ${padding};
        z-index: 9;
    `}
`

export default Submission
